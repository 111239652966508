import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage"
const firebaseConfig = {
  apiKey: "AIzaSyBdAmhuuf_5N7mgdPgtLJd1tPQnAeB-5U0",
  authDomain: "bk-enterprises-79c7f.firebaseapp.com",
  projectId: "bk-enterprises-79c7f",
  storageBucket: "bk-enterprises-79c7f.appspot.com",
  messagingSenderId: "1660549632",
  appId: "1:1660549632:web:d3d3fb155629ee3096b6e5"
};
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);