import { observer } from 'mobx-react-lite';
import {
    Button,
    Container,
    Divider,
    Dropdown,
    Grid,
    Header,
    Icon,
    Image,
    Menu,
    Segment
  } from "semantic-ui-react";
  import "./WebHomePage.css";  
import { useStore } from '../../app/stores/store';
export default observer(function RefundCancellationPolicyPage() {
    const { commonStore,modalStore } = useStore();
    const currentyear = new Date().getFullYear();
    const randomNumberInRange = (min:number, max:number) => { 
        return Math.floor(Math.random()  
                * (max - min + 1)) + min;
    };
    return (
      <section data-bs-version="5.1" className="pricing2 cid-u7fhndmm5l" id="pricing02-2">
      <div className="container">
          <div className="row">
              <div className="title col-12 pb-5">
                  <h5 className="mbr-section-title mbr-fonts-style mb-3 display-2">Refund/Cancellation Policy</h5>
                  <h6 className="mbr-section-subtitle mbr-fonts-style mb-4 display-5">Order Cancellation</h6>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">Cancellation Window</h6>                        
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              You may cancel your order within 7 days of placing it, provided that the order has not already been processed for shipping. Once an order has been processed, it cannot be canceled
                          </p>
                      </div>
                  </div>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">Cancellation Procedure</h6>                        
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              To cancel your order, please contact our customer service team immediately at bk6398790917@gmail.com with your order number and request for cancellation. We will make every effort to accommodate your request if it falls within the specified cancellation window.
                          </p>
                      </div>
                  </div>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">Cancellation Confirmation</h6>                        
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              Upon successful cancellation, you will receive confirmation via email, and any pre-authorization charges on your payment method will be voided
                          </p>
                      </div>
                  </div>
              </div>
              
              <div className="title col-12 pb-5">                
                  <h6 className="mbr-section-subtitle mbr-fonts-style mb-4 display-5">Refund Policy</h6>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">Eligibility for Refunds</h6>                        
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              We offer refunds on eligible items returned in their original condition, unworn, unwashed, and with all tags attached, within 7 days of receiving your order.
                          </p>
                      </div>
                  </div>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">Refund Process</h6>                        
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              To request a refund, please follow the steps outlined in our Refund Policy. Once we receive and inspect your returned item(s), we will process your refund within 7 days to the original payment method used for the purchase.
                          </p>
                      </div>
                  </div>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">
                              Refund Exceptions
                          </h6>                        
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              Shipping fees are non-refundable, and a restocking fee may apply in some cases. Refunds may also be subject to deductions for any discounts, promotions, or coupons applied to the original purchase
                          </p>
                      </div>
                  </div>
              </div>
              <div className="title col-12 pb-5">                
                  <h6 className="mbr-section-subtitle mbr-fonts-style mb-4 display-5">Exchanges</h6>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">
                             Exchange
                          </h6>                    
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              We currently do not offer direct exchanges. If you wish to exchange an item for a different size, color, or style, we recommend returning the original item for a refund and placing a new order for the desired item.
                          </p>
                      </div>
                  </div>
              </div>
              <div className="card col-12 col-md-6">
                  <div className="card-wrapper">
                      <div className="top-line">
                          <h6 className="card-title mbr-fonts-style display-5">
                              Damaged or Defective Items
                          </h6>
                      </div>
                      <div className="bottom-line">
                          <p className="mbr-text mbr-fonts-style display-7">
                              If you receive a damaged or defective item, please contact us immediately upon receipt to arrange for a replacement or refund. We may request photographic evidence of the damage or defect to expedite the resolution process.
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
    )
})